import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`TekDatum’s QA Managed Services are specially designed to support your product, project, and team. `}</p>
    </PageDescription>
    <p>{`Our team of experienced QA Automation Engineers will `}<strong parentName="p">{`work closely`}</strong>{` with your development team to create a customized testing strategy that fits your specific needs.  Thus, you can expect:`}</p>
    <p>{`• `}<strong parentName="p">{`Cost savings`}</strong>{`: We will take care of all aspects of your QA Automation, including sourcing, training, and managing the QA team, which can save you time and money on recruiting, onboarding, and retention costs.`}</p>
    <p>{`• `}<strong parentName="p">{`Predictable costs`}</strong>{`: You will know the exact costs of the service and can budget accordingly, without having to worry about unexpected expenses or delays.`}</p>
    <p>{`• `}<strong parentName="p">{`High-quality work`}</strong>{`: Our team of experienced QA Automation Engineers will ensure that your software is thoroughly tested and that any issues are addressed before the product is released to the market, reducing the risk of negative feedback and customer churn.`}</p>
    <p>{`• `}<strong parentName="p">{`Continuous improvement`}</strong>{`: We will continuously monitor and improve the testing strategy to ensure that it is optimized for your product, resulting in higher quality and more reliable software.`}</p>
    <p>{`• `}<strong parentName="p">{`Flexibility`}</strong>{`: Our team can adapt to changes in your business needs and technology landscape, ensuring that your software is always up to date and meets the latest industry standards.`}</p>
    <p>{`TekDatum will be present throughout the quality assurance process, with the best communication practices in order to define, design and build your product with a clear understanding of your business and goals.`}</p>
    <Row mdxType="Row">
  <Column colMd={6} colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAByHWQyJX/xAAZEAADAAMAAAAAAAAAAAAAAAAAARECEDH/2gAIAQEAAQUCSIZdpdf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAxACD/2gAIAQEABj8CIz//xAAcEAEBAAICAwAAAAAAAAAAAAARAQAhEDFBUYH/2gAIAQEAAT8hSQTrKZW/X3IDIGLp1iO/Lx//2gAMAwEAAgADAAAAEBzP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFRQcH/2gAIAQEAAT8QrUhETly9BltMJEcAj79wIVjkL1laV+nuVVVq9u//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/76c8606c8cd4a428d12090cc0a010f5c/b5e35/QA_process_black.webp 288w", "/static/76c8606c8cd4a428d12090cc0a010f5c/0bf43/QA_process_black.webp 576w", "/static/76c8606c8cd4a428d12090cc0a010f5c/73abe/QA_process_black.webp 1152w", "/static/76c8606c8cd4a428d12090cc0a010f5c/b9259/QA_process_black.webp 1728w", "/static/76c8606c8cd4a428d12090cc0a010f5c/37df7/QA_process_black.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/76c8606c8cd4a428d12090cc0a010f5c/2fc17/QA_process_black.jpg 288w", "/static/76c8606c8cd4a428d12090cc0a010f5c/91537/QA_process_black.jpg 576w", "/static/76c8606c8cd4a428d12090cc0a010f5c/a10f6/QA_process_black.jpg 1152w", "/static/76c8606c8cd4a428d12090cc0a010f5c/495f1/QA_process_black.jpg 1728w", "/static/76c8606c8cd4a428d12090cc0a010f5c/3cb6a/QA_process_black.jpg 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/76c8606c8cd4a428d12090cc0a010f5c/a10f6/QA_process_black.jpg",
              "alt": "QA process",
              "title": "QA process",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </Column>
    </Row>
    <Caption fullWidth mdxType="Caption">
Standard Test Case Creation and Automation Flow at TekDatum
    </Caption>
    <h2>{`Requirements Review`}</h2>
    <p>{`QA team reviews requirements documents during each sprint cycle and performs
exploratory testing to understand the feature requirements better.
The QA team meets with BA’s and Devs if any clarifications are needed.
Besides, they also review wireframes and prototypes in preparation for the test process.
Prior to testing, they will define areas and sub-areas to test after meeting with stakeholders such as product managers, business analysts and developers. `}</p>
    <h2>{`Environment Setup`}</h2>
    <p>{`QA team will build or request a QA server for testing purposes, completely separate from development or production servers,
define automation tools for running regression tests, define general processes regarding builds installations; and, define and
setting up clients (browsers and mobile devices) for testing.`}</p>
    <p>{`Our team must setup a project using QA tools for bug tracking, case management, and so on.`}</p>
    <h2>{`Test Case Design`}</h2>
    <p>{`This activity corresponds, in essence, to the creation of the Test Plan. Each user story will have at least one test case that covers its functionality. We can use different test case management tools such as TestLink, TestRail, APTest and others.`}</p>
    <h2>{`Test Case Execution`}</h2>
    <p>{`With a stable build and a test plan designed, the QA team will  perform each test case automated via scripts or executed manually by a tester.
The test management tools can be used to provide you with an overview of the status of these tests and detect issues along the way.`}</p>
    <h2>{`Bug Management`}</h2>
    <p>{`We follow the bug Lifecycle scheme:`}</p>
    <Row mdxType="Row">
      <Column colMd={6} colLg={6} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHHeswhGJ//xAAaEAACAgMAAAAAAAAAAAAAAAABAgASAxET/9oACAEBAAEFAgV5gLMihGsdXNJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHxAAAQMDBQAAAAAAAAAAAAAAAQACEQMSQRAhMVFh/9oACAEBAAY/AhNPPKLrRHV6gODvQonZW40//8QAGxABAAIDAQEAAAAAAAAAAAAAAQAxESFREIH/2gAIAQEAAT8hDtG+dnJgrz2EUkIKFrCiA7D4PP/aAAwDAQACAAMAAAAQiw//xAAWEQADAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8QoX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFxEEGR8P/aAAgBAQABPxAfYUow6n7WMY0FYhpZy3rAJ4HZNlmJUPfQOJDTybvsvj//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/306e52c430464ca6d37447499c735288/b5e35/qa_buglife_cycle.webp 288w", "/static/306e52c430464ca6d37447499c735288/0bf43/qa_buglife_cycle.webp 576w", "/static/306e52c430464ca6d37447499c735288/73abe/qa_buglife_cycle.webp 1152w", "/static/306e52c430464ca6d37447499c735288/b9259/qa_buglife_cycle.webp 1728w", "/static/306e52c430464ca6d37447499c735288/37df7/qa_buglife_cycle.webp 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/306e52c430464ca6d37447499c735288/2fc17/qa_buglife_cycle.jpg 288w", "/static/306e52c430464ca6d37447499c735288/91537/qa_buglife_cycle.jpg 576w", "/static/306e52c430464ca6d37447499c735288/a10f6/qa_buglife_cycle.jpg 1152w", "/static/306e52c430464ca6d37447499c735288/495f1/qa_buglife_cycle.jpg 1728w", "/static/306e52c430464ca6d37447499c735288/3cb6a/qa_buglife_cycle.jpg 1920w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/306e52c430464ca6d37447499c735288/a10f6/qa_buglife_cycle.jpg",
              "alt": "Bug LifeCycle",
              "title": "Bug LifeCycle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span>
      </Column>
      <Column colMd={4} colLg={3} className="notes" mdxType="Column">
        <p><strong parentName="p">{`Bug status`}</strong></p>
        <ul>
          <li parentName="ul">{`Open (In progress, Waiting for info)`}</li>
          <li parentName="ul">{`Resolved`}</li>
          <li parentName="ul">{`Closed`}</li>
        </ul>
        <p><strong parentName="p">{`Bug Resolution`}</strong></p>
        <ul>
          <li parentName="ul">{`Fixed`}</li>
          <li parentName="ul">{`Cannot Reproduce`}</li>
          <li parentName="ul">{`Duplicate`}</li>
          <li parentName="ul">{`By design `}</li>
          <li parentName="ul">{`Won’t Fix`}</li>
        </ul>
      </Column>
    </Row>
    <h2>{`Regression Test`}</h2>
    <p>{`The team perform all parts of the regression testing process and ensure that any new changes did not break anything in existing features.
Also, they perform manual and automated tests to ensure that the software works correctly as designed by development. `}</p>
    <p>{`Depending on your requirements, we can perform web API automation (Rest and Soap services) and verify that it is working as expected.`}</p>
    <h2>{`Reports`}</h2>
    <p>{`Reports provide the information you need for timely decision making. Daily QA Reports provide information about bugs and areas of the system that need your attention.
If you need more detailed analyses, historical reports and regression tests are available. The QA team can also provide ad-hoc reports for special needs of the project manager or other stakeholders of the process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      